<template>
  <div id="whoweare">
    <!-- 主要内容 -->
    <!-- <div class="nav-reference">
      <TimeLineNav :timeLineList="timeLineList" />
    </div> -->
    <!-- <Footprint />
    <Concernt />
    <Solution />
    <Outcome /> -->
    <div v-html="data" class="whoweare-box">
      <!--      <div class="box-center">-->
      <!--        <img src="@/assets/images/about-us1.jpg" alt="" />-->
      <!--      </div>-->
      <!--      <div class="concerns-wrapper">-->
      <!--        上海市长益公益基金会是2019年2月经上海市民政局核准登记的非营利慈善组织，由长江商学院EMBA26期5班71位学子和班主任共同发起，基金会创造和提供公共价值，基金会的所有财产都属于社会公共财产。-->
      <!--        <div>-->
      <!--          愿景：构建互相关怀、信任认同的社会，使得每个人都能老有所依，老有所为。-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          使命：助力养老服务体系建设，让困境老人享有志愿性养老服务，让老人和参与者发挥社会价值和精神价值。-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          定位：助力乡村人才振兴，构建公益体系，打造公益创新平台。 价值观：-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          平等：每个人都值得理解和尊重。-->
      <!--          信任：以诚相待，人际信任让世界变得简单。-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          务实：从基层做起，持续服务。 快乐：快乐公益，增强获得感和幸福感。-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          成全：成全他人，成全组织，成全自己。-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="box-center">-->
      <!--        <img src="@/assets/images/about-us2.jpg" alt="" />-->
      <!--      </div>-->
      <!--      <div class="concerns-wrapper">-->
      <!--        <div style="font-size: 24px; color: #000;margin-bottom: 20px;">-->
      <!--          理事长介绍-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          龙陈先生，中国共产党党员，2016年5月和长江商学院EMBA26期5班全体学子及班主任共同发起“关怀乡村留守老人”公益项目，并于2019年发起长益基金会，担任第一届理事长。-->
      <!--        </div>-->
      <!--        <div>上海邦信阳中建中汇律师事务所 合伙人</div>-->
      <!--        <div>上海仲裁委员会仲裁员</div>-->
      <!--        <div>长江商学院上海校友会副会长</div>-->
      <!--        <div>长江商学院公益导师</div>-->
      <!--        <div>上海市湖南商会常务副会长</div>-->
      <!--        <div>上海市衡阳商会轮值会长</div>-->
      <!--        <div>上海财经大学硕士研究生导师</div>-->
      <!--        <div>华东理工大学兼职教授</div>-->
      <!--      </div>-->
      <!--      <div class="concerns-wrapper">-->
      <!--        <div style="font-size: 24px; color: #000;margin-bottom: 20px;">-->
      <!--          秘书长介绍-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          秘书长钟铁华女士，上海市湖南商会理事。-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          从事职业公益十年，曾就职深圳市龙越慈善基金会，参与“老兵回家”初创。2016年开始负责设计并执行“关怀乡村留守老人”公益项目，2019年办理成立上海市长益公益基金会，担任秘书长。-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <Infomation />
  </div>
</template>

<script>
// import TimeLineNav from "@/components/About/TimeLineNav";
// import Footprint from "./Footprint";
// import Concernt from "./Concern";
// import Solution from "./Solution";
// import Outcome from "./Outcome";
import Infomation from "./Infomation";
import { getConfig } from "@/api/category";

export default {
  name: "WhoWeAre",
  components: {
    // TimeLineNav,
    // Footprint,
    // Concernt,
    // Solution,
    // Outcome,
    Infomation
  },
  data() {
    return {
      timeLineList: [
        { name: "成长的足迹", anchor: "footprint" },
        { name: "关注的问题", anchor: "concern" },
        { name: "解决的方案", anchor: "solution" },
        { name: "项目的成果", anchor: "outcome" },

        { name: "基本的信息", anchor: "information" }
      ],
      data: null
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    async getConfig() {
      const res = await getConfig("AboutUs");
      if (res !== null) {
        try {
          this.data = res.value;
        } catch (e) {
          this.data = "";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#whoweare {
  font-family: "Ya Hei" !important;
  scroll-behavior: smooth;
  color: #1d1d1f;
  scroll-behavior: smooth;
}

// 栏目以及英文
.title {
  // font-family: "AlibabaPuHuiTi-FRegular" !important;
  text-align: start;
  // margin: 10px 10px 60px 10px;
  div {
    display: inline;
  }
  div:first-child {
    font-size: 24px;
    color: #000;
  }
  div:last-child {
    margin-left: 20px;
    color: #ccc;
  }
}
.whoweare-box {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  width: 1240px;
  margin: 0 auto;
  scroll-behavior: smooth;
  .box-center {
    display: flex;
    justify-content: center;
  }
}
.concerns-wrapper {
  position: relative;
  font-size: 16px;
  line-height: 34px;
  text-indent: 2em;
  width: 1240px;
  padding: 0 65px;
  margin: 0 auto;
  scroll-behavior: smooth;
  margin-top: 60px;
}
</style>
