<template>
  <div id="information-wrapper">
    <a href="" id="information" class="anchor"></a>
    <div class="title">
      <div>基金会基本信息</div>
      <div>/ Our basic information</div>
    </div>
    <div id="info-banner">
      <img src="@/assets/images/about-2.jpg" alt="" />
    </div>
    <div id="register-info" class="info-part">
      <div>登记注册信息</div>
      <div class="line"></div>
      <div class="main">
        <div class="left">
          <p>名称：{{ formatConfig(regData, "name") }}</p>
          <p>统一社会信用代码：{{ formatConfig(regData, "code") }}</p>
          <p>类型：{{ formatConfig(regData, "type") }}</p>
          <p>发证机关：{{ formatConfig(regData, "issuing") }}</p>
          <p>业务主管单位：{{ formatConfig(regData, "manager") }}</p>
        </div>
        <div class="right">
          <p>法定代表人：{{ formatConfig(regData, "corporate") }}</p>
          <p>注册资金：{{ formatConfig(regData, "registeredCapital") }}</p>
          <p>成立时间：{{ formatConfig(regData, "startTime") }}</p>
          <p>有效期限：{{ formatConfig(regData, "endTime") }}</p>
        </div>
      </div>
    </div>
    <div id="contact-info" class="info-part">
      <div>联络信息</div>
      <div class="line"></div>
      <div class="main">
        <div class="left">
          <p>地址：{{ formatConfig(contactData, "address") }}</p>
          <p>邮编：{{ formatConfig(contactData, "mail") }}</p>
          <p>电话：{{ formatConfig(contactData, "phone") }}</p>
        </div>
        <div class="right">
          <div>
            <img :src="formatConfig(contactData, 'WeChat')" alt="" />
            <span>微信公众号</span>
          </div>
          <div>
            <img :src="formatConfig(contactData, 'weibo')" alt="" />
            <span>新浪微博</span>
          </div>
        </div>
      </div>
    </div>
    <div id="bank-account-info" class="info-part">
      <div>银行账号</div>
      <div class="line"></div>
      <div class="main">
        <div class="left">
          <p>户名：{{ formatConfig(AccData, "accountName") }}</p>
          <p>开户银行：{{ formatConfig(AccData, "bank") }}</p>
          <p>帐号： {{ formatConfig(AccData, "account") }}</p>
        </div>
        <div class="right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfig } from "@/api/category";
import { formatConfig } from "@/utils/formatConfig";

export default {
  name: "Information",
  data() {
    return {
      regData: [],
      contactData: [],
      AccData: []
    };
  },
  mounted() {
    this.getRegConfig();
    this.getContactConfig();
    this.getAccConfig();
  },
  methods: {
    async getRegConfig() {
      const res = await getConfig("RegistrationInformation");
      if (res !== null) {
        try {
          this.regData = JSON.parse(res.value);
        } catch (e) {
          this.regData = [];
        }
      }
    },
    async getContactConfig() {
      const res = await getConfig("ContactInformation");
      if (res !== null) {
        try {
          this.contactData = JSON.parse(res.value);
        } catch (e) {
          this.contactData = [];
        }
      }
    },
    async getAccConfig() {
      const res = await getConfig("AccountInformation");
      if (res !== null) {
        try {
          this.AccData = JSON.parse(res.value);
        } catch (e) {
          this.AccData = [];
        }
      }
    },
    formatConfig(data, key) {
      return formatConfig(data, key);
    }
  }
};
</script>

<style lang="scss" scoped>
a#information {
  top: -50px;
}
#information-wrapper {
  padding: 80px 0;
  height: 1712px;
  width: 1240px;
  margin: 0 auto;
  // color: #000;
  scroll-behavior: smooth;
  position: relative;
}
.title {
  width: 1110px;
  margin: 0 auto;
}
#info-banner {
  margin: 50px 0;
}
.info-part {
  height: 380px;
  > div:first-child {
    height: 110px;
    line-height: 110px;
    padding: 0 65px;
    font-size: 20px;
    text-align: start;
  }
  .main {
    height: 270px;
    padding: 0 65px;
    display: flex;
    > div {
      flex: 1;
      height: 100%;
      text-align: start;
      padding: 40px 0;
    }
  }
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.line::after {
  content: "";
  display: block;
  position: absolute;
  width: 107px;
  height: 5px;
  background-color: #004898;
  right: 0;
  top: -4px;
}
// 联系信息
#contact-info {
  .right {
    display: flex;
    font-size: 20px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-right: 52px;
    }
    div:first-child {
      img {
        width: 150px;
        height: 150px;
      }
    }
    div:nth-child(2) {
      img {
        width: 135px;
        height: 135px;
        margin: 5px;
      }
    }
  }
}
</style>
